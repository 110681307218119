<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        xs="12"
      >
        <v-card
          class="mx-auto rounded"
        >
          <v-card-text>
            <v-simple-table
              :dense="false"
              :fixed-header="true"
              height="400px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="item in headers"
                      :key="item.text"
                      :class="`text-${item.align}`"
                    >
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <TbodyPreRender
                  :is-loading="isLoading"
                  :cols="headers.length"
                  :items-length="items.length"
                  :enable-app-selector="false"
                />
                <tbody v-if="!isLoading">
                  <tr
                    v-for="item in items"
                    :key="`user-tokens-tr-${item.idx}`"
                  >
                    <td class="text-left">
                      {{ item.device_type }}
                    </td>
                    <td class="text-left">
                      <v-chip
                        label
                        small
                        color="grey lighten-1"
                        class="my-1"
                        style="width: 80px; cursor: pointer;"
                        @click="copyToClipboard(item.access_token)"
                      >
                        <v-icon
                          left
                          small
                        >
                          content_copy
                        </v-icon>
                        Access
                      </v-chip>
                      <span class="caption pl-1">{{ item.access_token | truncate(50) }}</span>
                      <span class="caption pl-2">(만료 : {{ item.access_token_expired_at | dayjsLocalDatetime }})</span>
                      <br>
                      <v-chip
                        label
                        small
                        color="grey lighten-1"
                        class="my-1"
                        style="width: 80px; cursor: pointer;"
                        @click="copyToClipboard(item.refresh_token)"
                      >
                        <v-icon
                          left
                          small
                        >
                          content_copy
                        </v-icon>
                        Refresh
                      </v-chip>
                      <span class="caption pl-1">{{ item.refresh_token | truncate(50) }}</span>
                      <span class="caption pl-2">(만료 : {{ item.refresh_token_expired_at | dayjsLocalDatetime }})</span>
                    </td>
                    <td class="text-left">
                      <div
                        class="caption my-2"
                        style="height: 24px;"
                      >
                        {{ item.created_at | dayjsLocalDatetime }}
                      </div>
                      <div
                        class="caption my-2"
                        style="height: 24px;"
                      >
                        {{ item.updated_at | dayjsLocalDatetime }}
                      </div>
                    </td>
                    <td class="text-center">
                      <v-btn
                        small
                        color="red darken-4 white--text"
                        @click="deleteToken(item.idx)"
                      >
                        <v-icon small>
                          delete
                        </v-icon>
                        <span class="pl-2">삭제</span>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-pagination
          v-model="pagination.page"
          class="pagination-circle"
          circle
          :length="pagination.length"
          :total-visible="pagination.totalVisible"
          prev-icon="keyboard_arrow_left"
          next-icon="keyboard_arrow_right"
          @input="getItems(pagination.page)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import paginationLib from '../_elements/pagination/paginationLib'
import Api from './model/userApi'

export default {
  components: {
    TbodyPreRender
  },
  props: ['userIdx'],
  data() {
    return {
      isSetup: false,
      isLoading: false,
      logType: 'access_token',
      headers: [
        { text: 'Device Type', align: 'left' },
        { text: 'Token', align: 'left' },
        { text: 'Created at / Updated at', align: 'left' },
        { text: 'Actions', align: 'center' }
      ],
      items: [],
      pagination: {
        page: 1
      }
    }
  },
  created() {
    this.getItems(this.pagination.page)
  },
  methods: {
    async getItems(page) {
      this.isLoading = true
      const res = await Api.getTokens(this.userIdx, page)
      if (res.data.result === true) {
        this.items = res.data.data.items
        this.pagination = paginationLib.paginationMapper(res.data.data.pagination)
      }
      this.isLoading = false
    },
    async copyToClipboard(value) {
      const result = await this.$clipboard(value)
      if (result) {
        this.$bus.$emit('successMessage', '클립보드에 복사되었습니다')
      }
    },
    async deleteToken(idx) {
      const res = await Api.deleteToken(this.userIdx, idx)
      if (res.data.result === true) {
        this.items = _.cloneDeepWith(this.items, _.remove(this.items, (item) => item.idx === idx))
        this.$bus.$emit('successMessage', '삭제되었습니다')
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-circle {
  ::v-deep .v-pagination__item,
  ::v-deep .v-pagination__navigation {
      outline: none;
  }
}
</style>
