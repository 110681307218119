<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="사용자 조회" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <!-- max-width="375" -->
        <v-card
          class="mx-auto"
        >
          <v-img
            :src="user.user_extra.photo_url || `https://avatars.dicebear.com/api/jdenticon/${user.user_id}.svg`"
            height="240px"
            dark
          >
            <v-row
              class="fill-height"
              style="flex-flow: column;"
            >
              <v-spacer />

              <v-card-title
                class="white--text pl-12"
                style="background: rgba(0,0,0,0.52);"
              >
                <div class="subtitle-1 text-weight-bold">
                  {{ user.user_id }}
                </div>
              </v-card-title>
            </v-row>
          </v-img>

          <v-list>
            <ListItem
              prefix-icon="badge"
              caption="User ID"
              :title="user.user_id"
            />
            <ListItem
              :title="user.mail"
              caption="Mail Address"
              :prefix-icon="user.mail_verified ? 'mark_email_read' : 'mark_email_unread'"
            />

            <v-divider inset />

            <ListItem
              prefix-icon="event_available"
              caption="Created at"
              :title="user.created_at | dayjsLocalDatetime"
            />
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          class="mx-auto"
        >
          <v-list>
            <ListItem
              prefix-icon="badge"
              caption="Nickname"
              :title="user.user_extra.nickname"
            />
            <ListItem
              prefix-icon="badge"
              caption="Real Name"
              :title="user.user_extra.real_name"
            />

            <v-divider inset />

            <ListItem
              prefix-icon="badge"
              caption="Profile image url"
              :title="user.user_extra.photo_url || 'N/A'"
            />

            <v-divider inset />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon>settings</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>
                  <span class="caption">Privileges</span>
                  <v-btn
                    small
                    icon
                    class="mx-2"
                    @click="isUserPrivilegesEditmode=!isUserPrivilegesEditmode"
                  >
                    <v-icon small>
                      {{ (isUserPrivilegesEditmode) ? 'close' : 'edit' }}
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <VueJsonPretty
                    v-if="!isUserPrivilegesEditmode"
                    :data="user.user_extra.privileges"
                    class="py-4"
                  />
                  <div v-else>
                    <VJsoneditor
                      ref="user_privileges_editor"
                      v-model="user.user_extra.privileges"
                      class="json-editor-wrap"
                      :options="{ mode: 'code' }"
                      :height="'400px'"
                    />
                    <div class="text-center my-4">
                      <v-btn
                        color="primary"
                        class="mx-2"
                        @click="updateUserData('extra_privileges')"
                      >
                        <v-icon
                          small
                          class="pr-2"
                        >
                          save
                        </v-icon>
                        저장
                      </v-btn>
                      <v-btn
                        color=""
                        class="mx-2"
                        @click="isUserPrivilegesEditmode=false"
                      >
                        <v-icon
                          small
                          class="pr-2"
                        >
                          close
                        </v-icon>
                        닫기
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon>info</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>
                  <span class="caption">Extra Information</span>
                  <v-btn

                    small
                    icon
                    class="mx-2"
                    @click="isUserExtraEditmode=!isUserExtraEditmode"
                  >
                    <v-icon small>
                      {{ (isUserExtraEditmode) ? 'close' : 'edit' }}
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <VueJsonPretty
                    v-if="!isUserExtraEditmode"
                    :data="user.user_extra.extra"
                    class="py-4"
                  />
                  <div v-else>
                    <VJsoneditor
                      ref="user_privileges_editor"
                      v-model="user.user_extra.extra"
                      class="json-editor-wrap"
                      :options="{ mode: 'code' }"
                      :height="'400px'"
                    />
                    <div class="text-center my-4">
                      <v-btn
                        color="primary"
                        class="mx-2"
                        @click="updateUserData('extra_extra')"
                      >
                        <v-icon
                          small
                          class="pr-2"
                        >
                          save
                        </v-icon>
                        저장
                      </v-btn>
                      <v-btn
                        color=""
                        class="mx-2"
                        @click="isUserExtraEditmode=false"
                      >
                        <v-icon
                          small
                          class="pr-2"
                        >
                          close
                        </v-icon>
                        닫기
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <ChangePasswordComponent
          :user-idx="user.idx"
        />
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12">
        <PageTitleComponent title="발급 토큰 정보" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pa-0"
      >
        <UserTokens
          :user-idx="user.idx"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <PageTitleComponent title="접속정보" />
      </v-col>
    </v-row>
    <v-row>
      <UserActionLogs
        :user-idx="user.idx"
      />
    </v-row>

    <v-row
      justify="center"
      class="my-5"
    >
      <v-btn
        color=""
        class="mx-4"
        @click="backToPrevPage"
      >
        <v-icon small>
          grading
        </v-icon>
        <span class="pl-2">목록으로</span>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import VJsoneditor from 'v-jsoneditor'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import PageTitleComponent from '../_elements/title/PageTitle'
import ListItem from '../_elements/list/ListItem'
import UserActionLogs from './UserActionLogs'
import UserTokens from './UserTokens'
import Api from './model/userApi'
import ChangePasswordComponent from './ChangePassword'

export default {
  components: {
    ListItem,
    VJsoneditor,
    PageTitleComponent,
    UserActionLogs,
    UserTokens,
    VueJsonPretty,
    ChangePasswordComponent
  },
  data() {
    return {
      isLoading: false,
      query: '',
      pagination: {},
      isUserExtraEditmode: false,
      isUserPrivilegesEditmode: false,
      headers: [
        { text: 'idx', align: 'center' },
        { text: 'User Id', align: 'left' },
        { text: 'Name', align: 'left' },
        { text: 'Email', align: 'left' }
      ],
      user: {
        idx: undefined,
        user_extra: {
          photo_url: null
        }
      },
      items: [
        { tab: 'Overview', name: '기본정보' },
        { tab: 'AccessToken', name: 'Access Token' },
        { tab: 'RefreshToken', name: 'Refresh Token' }
      ]
    }
  },
  created() {
    this.setup()
  },
  methods: {
    setup() {
      const idx = Number(this.$route.params.idx)
      if (_.isFinite(idx) && idx > 0) {
        this.user.idx = parseInt(this.$route.params.idx, 10)
        this.getItem()
      } else {
        this.backToPrevPage()
      }
    },
    async updateUserData(dataType = '') {
      const updateData = { user_extra: {} }
      let triggerFunc = null

      if (dataType === 'extra_privileges') {
        updateData.user_extra.privileges = JSON.stringify(this.user.user_extra.privileges)
        // eslint-disable-next-line no-param-reassign
        triggerFunc = (o) => { o.isUserPrivilegesEditmode = false }
      }
      if (dataType === 'extra_extra') {
        updateData.user_extra.extra = JSON.stringify(this.user.user_extra.extra)
        // eslint-disable-next-line no-param-reassign
        triggerFunc = (o) => { o.isUserExtraEditmode = false }
      }

      const res = await Api.updateItem(this.user.idx, updateData)
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다.')
        if (triggerFunc) {
          triggerFunc(this)
        }
        this.isUserPrivilegesEditmode = false
        this.isUserExtraEditmode = false
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async getItem() {
      this.isLoading = true
      const res = await Api.getItem(this.user.idx)
      if (res.data.result === true) {
        const dt = res.data.data
        this.user = dt.user
      }
      this.isLoading = false
    },
    backToPrevPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'AdminUsers.Items') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/admin/users?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
.v-window.v-item-group.theme--light.v-tabs-items {
  border-top: 1px solid #CCC;
  margin-top: -0.5px;
  padding-top: 0.5px;
}
.v-window.v-item-group.theme--dark.v-tabs-items {
  border-top: 1px solid #333;
  margin-top: -0.5px;
  padding-top: 0.5px;
}
.json-editor-wrap {

  ::v-deep .jsoneditor-outer {
    min-height: 200px;
  }
  ::v-deep div.jsoneditor-tree {
    min-height: 200px-35px;
  }
  ::v-deep .jsoneditor-poweredBy {
    display: none;
  }
}

</style>
